import React, {useEffect} from "react";
import { createState, useState } from "@hookstate/core";
import {  useQueryClient, useQuery } from "react-query"
import {TRANSPORTISTA} from "./TYPES";
import {getUser} from "./index";
export const userStore = createState({loggedIn:false, user:null});

export function useUserStore(){
	const {data, status} = useQuery(TRANSPORTISTA.name, getUser);
	console.log({data, status});
	useEffect(()=>{
		console.log({data})
		if(data){
			userStore.set(p => ({user:data, loggedIn:true, status:"success"}));
		}else{
			userStore.set(p => ({...p,status:"success", loggedIn:false}));
		}
	},[data, status]);

	return {user: data, status};

}