import React from "react";
import { Route, Redirect } from "react-router-dom";
import "./Routes.scss";
import loadable from "@loadable/component";
import {trackPage} from "analytics";
import Header from "components/Header/Header";


import { useQuery } from "react-query";
import {TRANSPORTISTA} from "api/TYPES";
import {crudFunctions, getUser} from "api";
import { createState, useState } from '@hookstate/core';
import {userStore, useUserStore} from 'api/store';
import Perfil from "pages/Perfil/Perfil";

import { CircularProgress } from "@material-ui/core";
const fallback = ()=> <CircularProgress />

const Home = loadable(() => import("../pages/Home/Home"),fallback);
const NavBar = loadable(() => import("../components/NavBar/VNavBar"));
const InitialData = loadable(() => import("../pages/Login/InitialData"),fallback);
const InitialFiles = loadable(() => import("../pages/Login/InitialFiles"),fallback);
const EnRevision = loadable(() => import("../pages/Login/EnRevision"),fallback);
const TablaConductores = loadable(() => import("../pages/Conductor/TablaConductores"),fallback);
const TablaUnidades = loadable(() => import("../pages/Unidad/TablaUnidades"),fallback);
const TablaViajes = loadable(() => import("../pages/Viaje/TabsViajes"),fallback);
const Viaje = loadable(() => import("../pages/Viaje/Viaje"),fallback);
const TablaPagos = loadable(() => import("../pages/Pago/TablaPagos"),fallback);
const TablaSolicitudes = loadable(() => import("../pages/Solicitudes/TablaSolicitudes"),fallback);
const TabUsuarios = loadable(() => import("../pages/Configuracion/Usuarios/TabUsuarios"),fallback);

const Activar = loadable(() => import("../pages/Login/Activar"),fallback);
const Login = loadable(() => import("../pages/Login/Login"),fallback);
const Registro = loadable(() => import("../pages/Login/Registro"),fallback);
const Forgot = loadable(() => import("../pages/Login/Olvide"),fallback);
const Reset = loadable(() => import("../pages/Login/Reset"),fallback);
const PrivateRoute = loadable(() => import("./PrivateRoute/PrivateRoute"));
const PublicRoute = loadable(() => import("./PublicRoute/PublicRoute"));



const Routes = () => {
	const {data:user, status:s2} = useQuery(TRANSPORTISTA.name, getUser);

	// const {user} = useUserStore();
	const state = useState(userStore);
	const status = state.status.get();
	const loggedIn = state.loggedIn.get();
	// const user = state.user.get();
	
	console.log({ user, s2})
	React.useEffect(()=>{
		let path = window.location.pathname;
		const myregexp = /[0-9a-fA-F]{24}/g;
		path = path.replace(myregexp, "id");
		trackPage(path);
	},[window.location.pathname]);

	let RNavBar = window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" ? null : <><NavBar user={user} /></>;

	// if (status==="loading")return <>Loading</>;
	// if (status==="error")return <>Error</>;
	// if (status==="error"){
	// 	return <>
	// 		<PublicRoute user={user} exact path="/login" component={Login} />
	// 		<PublicRoute user={user} exact path="/registro" component={Registro} />
	// 		<PublicRoute user={user} exact path="/forgot" component={Forgot} />
	// 		<PublicRoute user={user} exact path="/reset/:token/:idNutriologo" component={Reset} />
	// 	</>;
	// }
	if (status==="loading")return <>Loading</>;

	if(user?.transportista?.status==="pendiente_documentos")return <>
		<Redirect to={{ pathname: "/pendiente-documentos" }} />
		{RNavBar}
		<div className="container">
			<InitialFiles />
		</div>
	</>;
	else if(user?.transportista?.status==="en_revision")return <>
		<Redirect to={{ pathname: "/en-revision" }} />
		{RNavBar}
		<div className="container">
			<EnRevision />
		</div>
	</>;
	else if(user?.transportista?.status==="pendiente_informacion")return <><Redirect to={{ pathname: "/pendiente-informacion" }} />  {RNavBar}<InitialData /></>;
	
	return (
		<>
			{RNavBar}

			<div style={{height:"100%", minHeight:"100vh"}}>
				<PublicRoute status={status} user={user} loggedIn={loggedIn} exact path="/login" component={Login} />
				<PublicRoute status={status} user={user} loggedIn={loggedIn} exact path="/registro" component={Registro} />
				<PublicRoute status={status} user={user} loggedIn={loggedIn} exact path="/forgot" component={Forgot} />
				<PublicRoute status={status} user={user} loggedIn={loggedIn} exact path="/reset/:token/:id" component={Reset} />
				<PublicRoute status={status} user={user} loggedIn={loggedIn} exact path="/activar/:token/:idUsuario" component={Activar} />

				<div style={{height:"100%"}} className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
					

					<PrivateRoute status={status} user={user} loggedIn={loggedIn} exact path="/" component={Home} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/setup" component={InitialData} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/conductores" component={TablaConductores} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/unidades" component={TablaUnidades} />
					<PrivateRoute status={status} exact user={user} loggedIn={loggedIn} path="/viajes/:seccion?" component={TablaViajes} />
					<PrivateRoute status={status} exact user={user} loggedIn={loggedIn} path="/viaje/:id/:seccion?" component={Viaje} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/pagos" component={TablaPagos} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/solicitudes" component={TablaSolicitudes} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/mis-documentos" component={InitialFiles} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/perfil/:seccion?" component={Perfil} />
					<PrivateRoute status={status} user={user} loggedIn={loggedIn} path="/usuarios" component={TabUsuarios} />

				</div>
			</div>
		</>
	);
	
	
};

export default Routes;
