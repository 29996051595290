
import React from "react";

import PropTypes from "prop-types";
import {crudFunctions} from "api";
import { useQuery } from "react-query";
import Select from "components/FormComponents/Select/Select";
import _ from "lodash";	

const Ref = (props) => {

	const actions = crudFunctions(props.dataType);

	let {data, status} = useQuery(props.dataType.name, actions.getAll);

	const options = React.useMemo(()=>{
		let optns = []
		if(props.extraItems){
			optns = [...props.extraItems, ...data];
		}
		optns.push({label:"sin selección", value:null});
		return optns.concat(data?.filter(o=> o._id && !o._trash && _.get(o, props.accessor || "nombre") ).sort((rowA, rowB) => {
			let a = _.get(rowA, props.accessor || "nombre");
			let b = _.get(rowB, props.accessor || "nombre");
			a = a ? a.toLowerCase() : a;
			b = b ? b.toLowerCase() : b;
			return a === b ? 0 : a > b ? 1 : -1;
		}).map(d=>({
			label: props.accessors ? props.accessors.map(a=>_.get(d, a)).join(props.join || " ") : _.get(d, props.accessor || "nombre"), 
			value:d._id
		})));
	},[data]);	

	const handleChange = (value) =>  {
		if(value===null)value="";
		props.onChange(value, props.name);
	};
	if(status==="loading")return <>Loading</>
	return <Select isMulti={props.isMulti} options={options} onChange={handleChange} value={props.value} label={props.label} />;
};



export default Ref;

Ref.propTypes = {
	selector: PropTypes.func,
	extraItems: PropTypes.array,
	onChange: PropTypes.func,
	name: PropTypes.string,
	hidden: PropTypes.bool,
	readOnly: PropTypes.bool
};