import queryClient from "./query";
import {setGlobalState} from "./store";
const axios = require("axios");
const token = window.localStorage.getItem(process.env.REACT_APP_KEY);
// export const socket = io.connect("http://localhost:3000/consultas_online");
 


// export const socket = io("http://localhost:3000/consultas_online");

let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 120000,
});
instance.interceptors.request.use(
	config=>{
		const t = window.localStorage.getItem(process.env.REACT_APP_KEY);
		config.headers.Authorization = `Bearer ${t}`;
		return config;

	}
)
export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
}

export const Authorization = `Bearer ${token}`;
export function get(uri, params){
	return new Promise((resolve, reject)=>{
		instance.get(uri,{params}).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export function post(uri, data, options){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
			resolve(response.data);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		instance.put(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function upload(uri, files){
	return new Promise((resolve, reject)=>{
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append(f.name,f.file);
			});
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response.data);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export const track = console.log
export const login = (data) => post("/transportista-login",data, { withCredentials: true });
export const logout = () => {
	console.log("LOGGING OUT")

	// setGlobalState("loggedIn",false)
	instance.defaults.headers["Authorization"] = null;

	queryClient.resetQueries()

	queryClient.removeQueries();
	queryClient.invalidateQueries();
	return post("/cliente-logout");
}
export const getCartaPorte = () => axios.get("/carta_porte.json");
export const aceptarSolicitud = (data) =>  post(`/solicitudes/${data.id_solicitud}/aceptar`,data, { withCredentials: true });
export const rechazarSolicitud = (id_solicitud) =>  post(`/solicitudes/${id_solicitud}/rechazar`, { withCredentials: true });
export const registro = (data) => post("/transportista-registro",data, { withCredentials: true });
export const getTiposUnidad = () => get("/tipos-unidad");
export const getTiposMercancia = () => get("/tipos-mercancia");
export const getViajes = (data) => get("/clientes/me/viajes")
export const getConsolidado = (id) => get(`/viajes-consolidados/${id}`)
export const getDedicado = (id) => get(`/transportistas/me/viajes/${id}`)
export const setFechaPagoViaje = ({id, dias}) => post(`/transportistas/me/viajes/${id}/fecha-pago`,{dias})

export const getUser =(id) => get(`/transportistas/me`)
export const getUsers =(id) => get(`/transportistas/me/usuarios`)
export const getHome =(id) => get(`/transportistas/me/home`);
export const getViaje = ({queryKey}) => get(`/transportistas/me/viajes/${queryKey[1]}`);
export const cambiarConductor = (idViaje, id_conductor) => post(`/transportistas/me/viajes/${idViaje}/cambiar-conductor`,{id_conductor});
export const cambiarUnidad = (idViaje, id_unidad) => post(`/transportistas/me/viajes/${idViaje}/cambiar-unidad`,{id_unidad});

export const postConsolidado = (data) => post("/viajes-consolidados",data)
export const postDedicado = (data) => post("/viajes-dedicados",data)
export const postProyecto = (data) => post("/proyectos",data)
export const getCliente = () => get("/clientes/me")
export const postCustomerCard = ({token, sessionId}) => post("/clientes/me/cards", {token, sessionId})
export const getCustomerCards = () => get("/clientes/me/cards")
export const pagarViajeDedicado = ({idViaje, cardId, sessionId}) => post(`/viajes-dedicados/${idViaje}`, {cardId, sessionId})
export const cotizarViajeDedicado = (data) => post(`/cotizar`, data);

export const crudFunctions = (type, replacements) => {
	const tipo = type.url;
	return {
		getAll: ()=>{
			return get(`/${tipo}`);
		},
		getOne: (id)=>{
			return get(`/${tipo}/${id}`);
		},
		getPaginated: ({queryKey})=>{
			console.log({queryKey},{page:queryKey[1].page, search:queryKey[1].filter, sort:queryKey[1].sort})
			return get(`/${tipo}`,{page:queryKey[1].page, search:queryKey[1].filter, sort:queryKey[1].sort});
		},
		insert:async ({files, ...data})=>{
			const response = await post(`/${tipo}`, data);
			if(files){
				const r = await upload(`/${tipo}/${response.data._id}/files`, files);
				return r;
			}
		},
		update:async (id, {files, ...data})=>{
			await put(`/${tipo}/${id}`, data);
			if(files){
				const r = await upload(`/${tipo}/${id}/files`, files);
				return r;
			}
		},
		trash:(id)=>{
			return put(`/${tipo}/${id}`, {_trash: true});
		},
		untrash:(id)=>{
			return put(`/${tipo}/${id}`, {_trash: false});
		},
		delete:(id)=>{
			return remove(`/${tipo}/${id}`);
		},
		...replacements
	};
};


export default {post, get, put, remove, upload, init};
