import React from "react";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import Input from "components/FormComponents/Text/Text";
import { useQuery } from "react-query";
import {TRANSPORTISTA} from "api/TYPES";
import {crudFunctions, getUser} from "api";

const Titulo = styled.div`
	font-size:1.5em;
	margin:10px 0px;

`;
const DataContainer = styled.div`
	margin-bottom:5px;
`
const Label = styled.span`
	font-weight:bold;
	margin-right:10px;
`;
const Text = styled.span`
	margin-bottom:20px;
`
export default function Perfil(){
	const {data:user, status} = useQuery(TRANSPORTISTA.name, getUser);
	console.log({user, status});
	return <div className="bg-white rounded-xl flex flex-col gap-2 p-5">
		<div className="text-xl text-blue font-black">Datos empresa</div>

		<div>
			<div className="text-sm text-gray">RFC:</div>
			<div className="text-lg text-blue font-black">{user?.transportista?.empresa.rfc}</div>
		</div>
		<div>
			<div className="text-sm text-gray">Razon social:</div>
			<div className="text-lg text-blue font-black">{user?.transportista?.empresa.razon_social}</div>
		</div>
		<div className="text-xl text-blue font-black mt-5">Datos usuario</div>

		<div>
			<div className="text-sm text-gray">Nombre:</div>
			<div className="text-lg text-blue font-black">{user?.usuario?.nombre}</div>
		</div>
		<div>
			<div className="text-sm text-gray">Correo electronico:</div>
			<div className="text-lg text-blue font-black">{user?.usuario?.correo}</div>
		</div>
		<div>
			<div className="text-sm text-gray">Password:</div>
			<div className="text-lg text-blue font-black"><CambiarPassword /></div>
		</div>
	</div>;
}

function CambiarPassword(){
	const [status, setStatus] = React.useState(1);
	return <>
		{status===1 && <>
			<Button onClick={()=>setStatus(2)}>Cambiar password</Button>
		</>}
		{status===2 && <DataContainer>
			<Label>Nuevo password</Label>
			<Input />

			<Label>Confirmar nuevo password</Label>
			<Input />
			<Button onClick={()=>setStatus(1)}>Guardar</Button>
		</DataContainer>}
	</>;
}