import React from "react";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Typography>
	);
}
export default TabPanel;

TabPanel.propTypes = {
	children: PropTypes.array,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	index: PropTypes.number
};