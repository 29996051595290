import React from "react";
import GoogleMap from "google-map-react";
import { TextField, Button, Grid} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import SearchIcon from '@material-ui/icons/Search';
import { useState } from "react";
import PropTypes from "prop-types";

const _ = require("lodash");

let Geocoder;
// const geocodeAddress = _.debounce((,4000)

export default function Location(props){
	const [address, setAddress] = useState("");
	const [coords, setCoords] = useState(props.value||[]);
	const handleApiLoaded = (map, maps) => {
		console.log(map,maps);
		Geocoder = new maps.Geocoder();
	};
	const initGeoCoder = (map, maps) => {
    
		console.log(Geocoder);
	};
	const onBoundsChange = (a) => {
		console.log(a);
	};
	const geocodeAddress =()=>
		Geocoder.geocode( { "address": address}, (results, status)=> {
			if (status == "OK") {
				setCoords([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
				props.onChange([results[0].geometry.location.lat(), results[0].geometry.location.lng()], props.name);
				return [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
			} else {
				return [];
				// alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	

	return (<>
		<div style={{padding:"5px"}}>Ingresa tu dirección y presiona ENTER para buscar</div>
		
		<TextField
			size="small"
			label="Buscar"
			variant="outlined"
			value={address}
			onKeyDown={(e)=>{
				if (e.key === 'Enter') {
					geocodeAddress();
				}
			}}
			onChange={event => {
				setAddress(event.target.value);
				// geocodeAddress(event.target.value);
			}}
		/>
		
		

		<div style={{height:"250px"}}>  
			<GoogleMap
				bootstrapURLKeys={{ key: "AIzaSyAouXmowJkyCZwEoZo-OFu76yd331rYLKc" }}
				center={coords.length ? coords:[19.4326068,-99.13539355] }
				defaultZoom={16}
				onBoundsChange={onBoundsChange}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
			>
				{coords.length && <Temp
					lat={coords[0]}
					lng={coords[1]}
					text="My Marker"
				/>}
			</GoogleMap>
		</div>
	</>);
}
const Temp = ()=>{
	return <RoomIcon fontSize="large" style={{color:"red"}}></RoomIcon>;
};

Location.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
	name: PropTypes.string,
};