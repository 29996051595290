import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";



import App from "./App.js";
import messaging,{notificationPermission} from "./my_firebase";
import { ThemeProvider } from "@material-ui/styles";
import Theme from "./Theme";
import {init} from "api/loader";
import api from "api";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import "Styles.module.scss";
import $ from "jquery"; 
import DialogContext from "Contexts/DialogContext";
import queryClient from "api/query";
import { QueryClientProvider } from "react-query"
window.$ = $;
init();
const moment = require("moment");
notificationPermission();

ReactDOM.render(
	
	<ErrorBoundary>
		<ThemeProvider theme={Theme}>
			<QueryClientProvider client={queryClient}>

				<DialogContext>
					<App />
				</DialogContext>
			</QueryClientProvider>
		</ThemeProvider>
	</ErrorBoundary>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register({
	onRegister:(registration)=>{
		messaging.useServiceWorker(registration);
	}
});