import MyTabs from "components/Tabs/VerticalTabs";
import React from "react";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import Input from "components/FormComponents/Text/Text";
import { useQuery } from "react-query";
import {TRANSPORTISTA} from "api/TYPES";
import {crudFunctions, getUser} from "api";
import InitialFiles from "pages/Login/InitialFiles"
import Info from "./Info"
import { useRouteMatch } from "react-router-dom";

const Titulo = styled.div`
	font-size:1.5em;
	margin:10px 0px;

`;
const DataContainer = styled.div`
	margin-bottom:5px;
`
const Label = styled.span`
	font-weight:bold;
	margin-right:10px;
`;
const Text = styled.span`
	margin-bottom:20px;
`
export default function Perfil(){
	let match = useRouteMatch();

	const tabs=[{
		label: "Perfil",
		value:"perfil",
		component:Info,
	},{
		label: "Mi información",
		value:"Mi información",
		component:InitialFiles,
	}];
	return <>
		<div className="p-10">
			<div className=" p-10 rounded-2xl">

				<MyTabs tabs={tabs} keys={["id"]} defSelected={match.params.seccion} urlKey="seccion" root={true} ></MyTabs>
			</div>
		</div>
	</>;
}
