import React from "react";
import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { withStyles, Popover, ClickAwayListener } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearIcon from '@material-ui/icons/Clear';
import es from "date-fns/locale/es";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const StyledDatePickerWrapper = styled.div`
	.react-datepicker {
		border:0;
	}
  .react-datepicker__header {
    background-color:white;
  }
`;
const CustomDatePicker = withStyles({
	input: {
		color:"gray"
	}
})(DatePicker);
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function DateRange(props) {
	const { column: { filterValue = [], setFilter, Header } } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [showPicker, setShowPicker] = React.useState(false);
	const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
		console.log({start, end})
		setFilter([start||null, end||null]);

		if(start && end){
			setShowPicker(false);
			// setStartDate(start);
			// setEndDate(end);
		}
		
  };
	const calcFilter= (tipo)=>()=>{
		switch(tipo){
			case "ayer": setFilter([moment().subtract(1,"day").startOf("day").toDate(), moment().subtract(1,"day").endOf("day").toDate()]);break;
			case "hoy": setFilter([moment().startOf("day").toDate(), moment().endOf("day").toDate()]);break;
			case "7": setFilter([moment().subtract(7,"day").startOf("day").toDate(), moment().endOf("day").toDate()]);break;
			case "14": setFilter([moment().subtract(14, "day").startOf("day").toDate(), moment().endOf("day").toDate()]);break;
			case "30": setFilter([moment().subtract(30, "day").startOf("day").toDate(), moment().endOf("day").toDate()]);break;
			case "semana": setFilter([moment().startOf("week").toDate(), moment().endOf("week").toDate()]);break;
			case "mes": setFilter([moment().startOf("month").toDate(), moment().endOf("month").toDate()]);break;
			case "mes_anterior": setFilter([moment().subtract(1,"month").startOf("month").toDate(), moment().subtract(1,"month").endOf("month").toDate()]);break;

		}
		setShowPicker(false)
	}
	 

	return (<>
		<div style={{display: "flex"}} className="relative">
			<div onClick={(e)=>(setShowPicker(true), setAnchorEl(e.currentTarget))} 			className={`bg-white border border-gray-light rounded px-5 py-2 w-full ${props.className}`}>
				{filterValue[0]&& filterValue[1] ? <>{moment(filterValue[0]).format("DD/MMM/YY")} - {moment(filterValue[1]).format("DD/MMM/YY")}</> : <span className="text-gray-light">Buscar {Header}</span>}
			</div>
		</div>
		<Popover
			// id={id}
			open={showPicker}
			anchorEl={anchorEl}
			// onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<ClickAwayListener onClickAway={()=>setShowPicker(false)}>

				<div className="flex gap-5 bg-white p-5 items-center">
					<StyledDatePickerWrapper>
						<DatePicker
						wrapperClassName="datePicker"
						selected={filterValue[0]}
						onChange={onChange}
						startDate={filterValue[0]}
						endDate={filterValue[1]}
						selectsRange
						inline
						style={{position:"absolute", zIndex:9}}
						customInput={<input />}
					/>
				</StyledDatePickerWrapper>
				<div className="flex flex-col gap-2">
					<div onClick={calcFilter("hoy")} className="cursor-pointer">Hoy</div>
					<div onClick={calcFilter("ayer")} className="cursor-pointer">Ayer</div>
					<div onClick={calcFilter("7")} className="cursor-pointer">Ultimos 7 dias</div>
					<div onClick={calcFilter("14")} className="cursor-pointer">Ultimos 14 dias</div>
					<div onClick={calcFilter("30")} className="cursor-pointer">Ultimos 30 dias</div>
					<div onClick={calcFilter("semana")} className="cursor-pointer">Esta semana</div>
					<div onClick={calcFilter("mes")} className="cursor-pointer">Este mes</div>
					<div onClick={calcFilter("mes_anterior")} className="cursor-pointer">Mes anterior</div>
				</div>
			</div>
			</ClickAwayListener>
		</Popover>
		</>);

			{/* <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				<>
					<StyledDatePicker
						style={{paddingRight:"5px",width:"100%"}}
						inputProps={{style:{color:"gray"}}}
						size="small"
						inputVariant="outlined"
						autoOk
						emptyLabel="De"
						format="dd/MM/yyyy"
						views={["year", "month", "date"]}
						value={filterValue[0] || null}
						onChange={(date) => {
							setFilter([date ? new Date(date).toISOString() : undefined, filterValue[1]]);
						}}
					/>

					<StyledDatePicker
						style={{width:"100%"}}
						size="small"
						inputProps={{style:{color:"gray"}}}
						inputVariant="outlined"
						emptyLabel="Hasta"
						autoOk
						format="dd/MM/yyyy"
						views={["year", "month", "date"]}
						value={filterValue[1] || null}
						onChange={date => {
							setFilter([filterValue[0], date ? new Date(date).toISOString() : undefined]);
						}}
					/>
					{(filterValue[0] || filterValue[1]) && <IconButton style={{padding:0}} onClick={()=>setFilter(() => [undefined, undefined])}><ClearIcon /></IconButton>}
				</>
			</MuiPickersUtilsProvider> */}
		{/* </div> */}

}
export default DateRange;

DateRange.propTypes = {
	column: PropTypes.object
};