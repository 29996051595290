import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";
import styles from "Styles.module.scss";
import { useWindowSize } from "Hooks";
import PropTypes from "prop-types";
import styled from "styled-components"
export default function MyTabs(props){
	const {tabs, defSelected, urlKey, root, icon} = props;
	let match = useRouteMatch();
	let history = useHistory();
	const {innerWidth} = useWindowSize();
	const [selected, setSelected] = useState(defSelected ? +defSelected : false);


	const handleChange = (v) => {

		if(props.disableUrlParams){
			setSelected(v);
			return;
		}
		let prevParams = props.keys? props.keys.reduce((acc, val)=>{
			return {...acc, [val]: match.params[val] ? match.params[val] : 0};
		},{}) : {};

		prevParams[urlKey] = v;

		const path = generatePath(match.path, prevParams);
		history.push(path);


	};

	useEffect(()=>{
		props.onChangeTab && props.onChangeTab(selected);
	},[selected]);
	useEffect(()=>{

		if(!match.params[urlKey]){
			// handleChange(0);
		}

		if(!match.params[urlKey]){
			setSelected(0);
		}
		else
			setSelected(+match.params[urlKey]);


	},[match.params]);

	console.log({tabs})
  
	return <StyledTabs className="tabs max-sm:flex max-sm:flex-col">
	
		<div className="tab flex sm:flex-row md:flex-col w-48 ">
			{tabs.map((tab, idx)=><>
				{idx===selected ? 
					<button className={`text-blue font-bold md:mt-10 ${idx===selected ? "bg-white max-sm:rounded-t-lg md:rounded-l-lg shadow-md-bottom color-blue" : ""}`} onClick={()=>handleChange(idx)}>{tab.selectedLabel ? tab.selectedLabel : tab.label }</button>
				:
					<button className={`text-gray font-bold md:mt-10 ${idx===selected ? "bg-white max-sm:rounded-t-lg md:rounded-l-lg shadow-md-bottom color-blue" : ""}`} onClick={()=>handleChange(idx)}>{tab.unselectedLabel ? tab.unselectedLabel : tab.label}</button>
				}

			</>)}
		</div>
		{tabs.map((tab, index)=>
			<React.Fragment key={index} className="tabContent">
				{/* {props.children} */}
				{index===selected && <tab.component {...tab.props} />}
				
			</React.Fragment>
		)}


	</StyledTabs>
	return <>
		<Tabs
			value={selected}
			onChange={(e,v)=>handleChange(v)}
			variant={innerWidth < 990 ? "scrollable" : "fullWidth"}
			scrollButtons={innerWidth < 769 ? "on" : "auto"}
			style={{marginBottom:"20px"}}
			className={styles.flexContainer}
			textColor="primary"
			indicatorColor="primary">
			{tabs.map((tab, idx)=>
				<Tab style={{height:root?"90px":"60px", fontWeight:"bold"}} key={tab.label} icon={icon ?
					<img
						className="image-tab"
						style={{height:"20px",objectFit:"contain"}}
						src={selected === idx ? tab.selectedImg : tab.img}
						alt="sección"
					/> : undefined
				} label={tab.label} />
			)}
		</Tabs>
		{tabs.map((tab, index)=>
			<React.Fragment key={index}>
				<TabPanel value={selected} index={index}>
					{props.children}
					<tab.component {...tab.props} />
				</TabPanel>
			</React.Fragment>
		)}
	</>;
};

MyTabs.propTypes = {
	children: PropTypes.element,
	tabs: PropTypes.array, 
	defSelected: PropTypes.string, 
	urlKey: PropTypes.string, 
	root: PropTypes.bool, 
	icon: PropTypes.bool,
	disableUrlParams: PropTypes.bool,
	keys: PropTypes.array,
};
const StyledTabs = styled.div`
* {box-sizing: border-box}

/* Style the tab */
.tab {
  float: left;
  // border: 1px solid #ccc;
  // background-color: #f1f1f1;
  // width: 30%;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  display: block;
  // color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
}

/* Create an active/current "tab button" class */
.tab button.active {
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 300px;
}
`