import React, {useMemo, useState, useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {Button, IconButton} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ClearIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import styled from "styled-components";
import AttachFileIcon from '@material-ui/icons/AttachFile';
const StyledInput = styled.label`
	// background:var(--colors-secondary);
	color:white;
	padding: 10px;
  border-radius: 10px;
	display:inline-flex;
	margin-top:10px;
`
const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out"
};

const activeStyle = {
	borderColor: "#2196f3"
};

const acceptStyle = {
	borderColor: "#00e676"
};

const rejectStyle = {
	borderColor: "#ff1744"
};
const filesString = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow";
export default function StyledDropzone(props) {
	console.log({props})
	const files = React.useMemo(()=> {
		if(props.files){
			if(Array.isArray(props.files)) return props.files.filter(f=>f.name===props.name)
			else if(props.files.name===props.name)return props.files
			else return []
		}return []
	},[props.files, props.name]);


	// const [hasInitialFiles] = useState(props.value);
	const [changing, setChanging] = useState(false);
	const onDrop = useCallback(e => {
		const acceptedFiles = [...e.target.files];
		e.persist();
		// Do something with the files
		// console.log(props.multipleFiles);
		if(acceptedFiles.length>0){

			if(props.multipleFiles){
				props.onFile({files: acceptedFiles}, props.name, props.single);
			}else{
				setChanging(false);
				props.onFile({files: acceptedFiles}, props.name, props.single);
			}
			
		}
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		accept: props.accept ? props.accept : (props.acceptFiles ? filesString : "image/*"),
		onDrop
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject
	]);
	const circleStyle = props.circle ? {borderRadius: "50%", height: "200px", margin: "auto", objectFit:"cover"} : {};
	const value = Array.isArray(props.value) ? props.value : [props.value];
	return (
		<div className="w-full">
			<div className="flex justify-between items-center">
				<div className="w-full">
					<div>
						{props.label}
					</div>
					<div>
						{props.sublabel}
					</div>
				</div>

			{/* For already loaded images */}
			<div>
				{value?.map(file=><>
					{props.download && <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} download><IconButton><GetAppIcon /></IconButton></a>}

						{((file?.tipo?.includes("image") || file?.tipo?.includes("png") || file?.tipo?.includes("jpeg") || file?.tipo?.includes("jpg")) && !props.hideFile)
						? 
						<div style={{height:"100%"}}>
							<img style={{ width: "100px", ...circleStyle}} src={`${process.env.REACT_APP_FILE_URL}${file.path}`} />
						</div>
						:
						file?.tipo
						}
					</>)
				}
			</div>
			

			{/* For loading images */}
			<div className="flex flex-col">
				{files?.map((file,idx)=>(
					<div key={idx} className="flex" style={{display: "flex", margin:"5px 0"}}>
						<ClearIcon onClick={() => props.removeFile(file.file)} fontSize="small" style={{color:"gray"}} />
						<p className="whitespace-nowrap	">{JSON.stringify(file.file.name)}</p>
					</div>
				))}
				{((!props.value && files.length===0) || changing || (props.multipleFiles && !props.value) ) ?null:
					<Button variant="contained" color="primary" size="small" onClick={()=>setChanging(true)}>Cambiar</Button>
				}

			</div>

			{/* For no image */}
			{props.readOnly ? null : <>
				{((!props.value && files.length===0) || changing || (props.multipleFiles && !props.value) ) ?
					<>
						
							
						<label for={`input${props.name}`}  className="px-10 border py-5 rounded-xl border-dashed border-gray w-full text-center" >
							Seleccionar archivo
							{/* <AttachFileIcon style={{width:20}} />
							<div style={{alignSelf:"center"}} className="p-10 border-dashed border-gray">Seleccionar archivo</div> */}
						</label>
						<input multiple={props.multipleFiles} accept={props.accept ? props.accept : (props.acceptFiles ? filesString : "image/*")} onChange={onDrop} id={`input${props.name}`} type="file" style={{display:"none"}} />
						
						
					</>
					:
					<div>
						{/* <div>{files?.file?.name||""}</div> */}
					</div>
				}
			</>}
		</div>
			
			
		</div>
	);
}

StyledDropzone.propTypes = {
	onFile: PropTypes.func,
	name: PropTypes.string,
	single: PropTypes.bool,
	accept: PropTypes.bool,
	acceptFiles: PropTypes.bool,
	circle: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	download: PropTypes.bool,
	files: PropTypes.object
};