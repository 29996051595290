import React, {useRef, useEffect} from "react";
import Filter from "components/Filter/Filter";

// import styled from 'styled-components'
import { useTable, useFilters, useSortBy, usePagination, useBlockLayout } from "react-table";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import ArrowUp from "assets/img/arrow-up.svg";
import ArrowDown from "assets/img/arrow-down.svg";
import Arrows from "assets/img/arrows.svg";
import TextCell from "../CellTypes/TextCell/TextCell";
import DefaultColumnFilter from "../FilterTypes/DefaultFilter/DefaultFilter";
import "./BaseTable.scss";

const moment = require("moment");
function generatePages(currentPage, totalPages) {
	let pages = [];

	for (let i = currentPage - 3; i < currentPage+3; i++) {
		if(i>=0 && i<totalPages){
			pages.push(i);
		}
	}
	return pages;
}
function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;
// Our table component
export default React.memo(function Table(props) {

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				filterValue = String(filterValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				return rows.filter(row => {
					const rowValue = String(row.values[id]).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
					
					return rowValue !== undefined
						? rowValue.includes(filterValue)
						: true;
				});
			},
			daterange:(rows, id, filter) => {
				if (!filter[0] || !filter[1]) {
					return rows;
				}
				return rows.filter(row=>{
					if (moment(row.values[id]).isBetween(filter[0], filter[1] ,"days","[]") ) {
						return true;
					}
				});
			}
		}),
		[]
	);
	const sortTypes = React.useMemo(
		() => ({
			basic: (rowA, rowB, columnId) => {
				let a = rowA.values[columnId];
				let b = rowB.values[columnId];
				a = a ? String(a).toLowerCase() : a;
				b = b ? String(b).toLowerCase() : b;
				return a === b ? 0 : a > b ? 1 : -1;
			},
			date: (rowA, rowB, columnId) => {
				let a = rowA.values[columnId];
				let b = rowB.values[columnId];
				a = moment(a).format("YYYYMMDD");
				b = moment(b).format("YYYYMMDD");
				return a === b ? 0 : a > b ? 1 : -1;
			},
			customSort:props.options?.customSort
			
		}),
		[props.options]
	);
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
			Cell:TextCell,
			filter:"text",
			sortType: "basic"
		}),
		[]
	);

	const { columns, options, data } = props;
	const isOrderable = options && options.orderBy;
	const defaultSorting = (options && options.defaultSorting) ? options.defaultSorting : undefined ;
	const hiddenColumns = (options && options.hiddenColumns) ? options.hiddenColumns : [] ;

	const [totalRows, setTotalRows] = React.useState(props.totalRows || data.length);
	let refTable = useRef(null);
	let initialState = {
		hiddenColumns:["_id", ...hiddenColumns]
	};

	if(defaultSorting){
		initialState.sortBy = [{
			id: defaultSorting.id,
			desc: defaultSorting.desc
		}];
	}
	// else{
	// 	initialState.sortBy = [{
	// 		id: "nombre",
	// 		desc: false
	// 	}];
	// }
	

	const [filteredData, setFilteredData] = React.useState(data);
	const [scroll, setScroll] = React.useState(0);



	React.useEffect(()=>{
		setFilteredData(data);

		if(!props.filters){
			setFilteredData(data);
		}
		setTotalRows(props.totalRows || data.length);
	},[data]);

	useEffect(()=>{
		changePage(0);
	},[filteredData]);

	const changePage = (page) =>{
		if(!props.manualPagination){
			gotoPage(page);
		}
	};
	useEffect(() => {
		if(props.getTable) {
			props.getTable(refTable);

		}
	}, [refTable]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: { pageIndex, sortBy },
		previousPage,
		nextPage,
		gotoPage,
		canPreviousPage,
		canNextPage,		
	} = useTable(
		{
			columns: columns,
			data: filteredData,
			defaultColumn,
			filterTypes,
			initialState,
			autoResetPage: props.skipPageReset==undefined ? true : props.skipPageReset,
			autoResetPageIndex:  props.skipPageReset==undefined ? true : props.skipPageReset,
			autoResetFilters: false,
			autoResetSortBy: false,
			sortTypes:sortTypes,
			totalRows:totalRows,
			pageCount:Math.ceil(totalRows/10),
			manualPagination:props.manualPagination,
			manualSortBy: props.manualPagination,
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination,
		props.options?.useBlockLayout ? useBlockLayout : useSortBy,
	);
	const handleRowClick = (row)=>{
		if(props.onClick){
			props.onClick(row.cells[0]);
		}
	};
	React.useEffect(()=>{
		props.onPaginationChange && props.onPaginationChange(pageIndex);
	},[pageIndex]);
	React.useEffect(()=>{
		props.onSortChanged && props.onSortChanged(sortBy);
	},[sortBy]);
	

	const pages = React.useMemo(()=>{
		return generatePages(pageIndex, pageOptions.length)
	},[pageIndex, pageOptions.length, filteredData])
	console.log({pages})
	const filters = <Filter type={props.type} gotoPage={changePage} data={data} filteredData={setFilteredData} filtros={props.filters} onFilterChange={props.onFilterChange} />;
	return (
		<div className="">
			<div>
				{props.filters && filters}
			</div>
			<div className="pt-2" style={{ overflowX: "auto" }}>
				<div>
					{props?.showScroll?.status &&
							<div style={{display: "flex", justifyContent: "space-between", flexDirection: scroll < 0 ? "row" : "row-reverse", position: "sticky", top: 0, zIndex: 10000}}>
								{scroll < 0 && <IconButton style={{color: "white", background: "#a5dd72"}} onClick={()=>setScroll(scroll + (props.showScroll?.width / props.showScroll?.lengthColumns))}><ArrowBackIosIcon /></IconButton>}
								<IconButton style={{color: "white", background: "#a5dd72"}} onClick={()=>setScroll(scroll - (props.showScroll?.width / props.showScroll?.lengthColumns))}><ArrowForwardIosIcon /></IconButton>
							</div>
					}
					<table ref={refTable} {...getTableProps()} className="table" style={{transform: `translatex(${scroll}%)`}}>
						<thead className="header">
							{headerGroups.map((headerGroup,idx) => (
								<tr key={idx} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<th
											key={column.id}
											{...column.getHeaderProps(
												isOrderable ? column.getSortByToggleProps():undefined
											)}
											className={"text-left whitespace-nowrap	pr-4 text-gray font-black py-5 "+ ((column.render("id") === "actions") ? "actions-table" : undefined)}
										>
											<div className="flex gap-2">
												{(isOrderable && !column.disableSortBy) && <span>
														{column.isSorted
															? column.isSortedDesc
																? <img src={ArrowUp} className="h-1"  />
																: <img src={ArrowDown} className="h-1"  />
															: <img src={Arrows} className="h-3"  />}
													</span>}
												{column.render("Header")}
												
											</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()} className="body ">
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr onClick={(event)=>handleRowClick(row, event)} key={i} {...row.getRowProps()} className={`container-element ${props.rowStyle}`}>
										{row.cells.map(cell => (
											<td key={cell.id} {...cell.getCellProps()} className={`py-3 pr-4 element ${cell.column.render("id") === "actions" ? "actions-table":""}`}>
												{cell.render("Cell")}
											</td>	
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="float-right">
				<div className="container-navigation-table flex align-items gap-7">
					<button
						className=" bg-transparent"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						<ArrowBackIosIcon style={{ fontSize: 10 }} className="w-3 text-[5px]" />
						{/* Anterior */}
					</button>
					<div className="flex gap-5 items-center">
						{pages.map(n=><div className={`cursor-pointer	 ${n===pageIndex ? "text-blue font-black text-lg" : ""}`} onClick={()=>gotoPage(n)}>{n+1}</div>)}
						{/* Página {" "}
						<em>
							{pageIndex + 1} de {pageOptions.length}
						</em> */}
					</div>
					<button
						className="bg-transparent"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						<ArrowForwardIosIcon style={{ fontSize: 10 }} className="w-3 text-[5px]" />
						{/* Siguiente */}
					</button>
				</div>
				<div style={{float:"right"}} className="mt-5">{props.footerComponent}</div>
			</div>
		</div>
	);
});
