import React, {useEffect} from "react";
import Element from "../Element";
import Group from "../Group/Group";
import { Card, Grid, Typography, Button, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import "./MultiGroup.css";
const MultiGroup = props => {
	// useEffect(() => {
	// 	if(props.value.length === 0){
	// 		handleNew();
	// 	}
	// });
	const handleNew = () => {
		props.onChange([...props.value, {} ], props.name);
	};
	const handleDelete = (idx) => {

		const newVal = props.value.filter((obj,index)=> idx!==index);

		props.onChange(newVal, props.name);
	};
	const handleChange = ( val, name, idx) => {
		const changed = props.value.map((item, index)=>{
			if(index===idx){
				return {...item, [name]:val};
			}
			return item;
		});
		props.onChange(changed, props.name);
	};
	return (

		<>
			<div style={{textAlign:"right"}}>

	<Button color="primary" variant="contained" onClick={handleNew}>{props.labelAddButton ? props.labelAddButton : "Agregar"}</Button>
			</div>
			{props.value.map((item, idx)=>
				<>
					<div style={{display:"flex", alignItems: "center"}}>
						<Group horizontal={props.horizontal} className="card-item" element={props.element} onChange={(val, name)=>handleChange(val, name, idx)} value={item} />
						<div>
							<ClearIcon onClick={()=>handleDelete(idx)} fontSize="small" style={{color:"red"}} />

						</div>
					</div>

					{/* <Card style={{marginBottom:"20px"}} key={item.tempid}>
						<Grid
							container
							direction="column"
							justify="space-between"
							style={props.horizontal ? {padding: "20px", display: "flex", flexDirection:"row", alignItems:"center"} : { padding: "20px" }}
							spacing={2}
						>
							<div style={{display:"flex",marginBottom:"10px", alignItems:"center"}}>
								<div>
									{props.element.fields.map((field) => (
										<Grid key={field.name} item style={props.horizontal && {width:`calc(90%/${props.element.fields.length})`}}>
											<Grid
												container
												alignItems="center"
												justify="space-between"
												spacing={2}
											>
												{field.label &&
												<Grid item>
													<Typography>{field.label}</Typography>
												</Grid>
												}
												<Grid item>
													<Element
														element={field}
														value={item[field.name]}
														onChange={(val)=>handleChange(field.name, val, idx)}
														// showLabel={false}
													></Element>
												</Grid>
											</Grid>
										</Grid>
										
									))}
							</div>
							
							<ClearIcon onClick={()=>handleDelete(idx)} fontSize="small" style={{color:"red"}} />
							</div>
						</Grid>
					</Card> */}
				</>
			)}
		</>
    
	);
};
export default MultiGroup;

MultiGroup.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
	name: PropTypes.string,
	labelAddButton: PropTypes.string,
	horizontal: PropTypes.bool,
	element: PropTypes.object
};