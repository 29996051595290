import React from "react";
import {
	FormControl,
	withStyles,
	InputLabel
} from "@material-ui/core";
import PropTypes from "prop-types";
import Select from 'react-select'
import styled from "styled-components";
const StyledSelect = styled(Select)`
	min-width:150px;
	background:transparent
`
const MySelect = (props) => {
	const value = React.useMemo(()=>{
		if(props.isMulti){
			return props.value?.map(v=>props.options?.find(d=>d.value===v)) || null;
		}else{
			if(props.value==="")return null
			return props.options?.find(d=>d.value===props.value)||null;
		}
		
	},[props.options, props.value]);
	const options = React.useMemo(()=>props.options?.filter(o=>!o._trash),[props.options]);
	

	const handleChange = (value) =>  {
		if(props.isMulti){
			value = value.map(v=>v.value);
		}else{
			value = value.value;
		}
		props.onChange(value, props.name);
		if(!value)value=null;
	};
	// className={`bg-blue-light rounded-3xl px-5 py-2 w-full ${props.className}`}
	return <StyledSelect  placeholder={props.label} isMulti={props.isMulti} options={options} onChange={handleChange} value={value} menuPortalTarget={document.body} 
		styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) , control: (base) => ({...base, background:"#EEF7FE", borderRadius:20, border:0, padding:"2px 5px", width:"100%", ...props.style}) , placeholder:base=>({...base, ...props.placeholderStyle})}}  />;
};


export default MySelect;

MySelect.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	options: PropTypes.array,
	name: PropTypes.string,
	onChange: PropTypes.func
};