import React, {useState} from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import es from "date-fns/locale/es";
import PropTypes from "prop-types";

import styled from "styled-components";
const StyledDatePicker = styled(DatePicker)`
	fieldset{
		border-color:#E66D1C;
	}	
`;
const StyledDateTimePicker = styled(DateTimePicker)`
	fieldset{
		border-color:#E66D1C;
	}	
`;
const StyledTimePicker = styled(TimePicker)`
	fieldset{
		border-color:#E66D1C;
	}	
`;
export default function CalendarPicker(props) {
	const [msg, setMsg] = useState("");
	const onChange = (date) => {
		props.onChange(date.toISOString(), props.name);
		setMsg(null);
		if(date.getHours() < 8){
			setMsg(`Seleccionaste las ${date.getHours()}am, si deseas seleccionar las ${date.getHours()}pm por favor verifica la información`);
		}
	};
	return (
		<div>
			<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				{props.subtype==="datetime" ?
					<StyledDateTimePicker
						size="small"
						inputVariant="outlined"
						format="dd/MM/yyyy HH:mm"
						minutesStep={5}
						ampm={false} 
						value={props.value?props.value:null}
						onChange={onChange}
						openTo={props.openTo&&props.openTo}
					/>


					:
					props.subtype==="time" 
						?
						<StyledTimePicker
							size="small"
							inputVariant="outlined"
							autoOk
							ampm={false} 
							minutesStep={5}
							value={props.value?props.value:null}
							onChange={onChange} 
						/> 
						:
						<StyledDatePicker
							size="small"
							inputVariant="outlined"
							autoOk
							format="dd/MM/yyyy"
							views={["year", "month", "date"]}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date.toISOString(), props.name)} 
							openTo={props.openTo&&props.openTo}
						/>
				}

			</MuiPickersUtilsProvider>
			{msg && <div style={{color:"red"}}>{msg}</div>}
		</div>
	);
}

CalendarPicker.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	name: PropTypes.string,
	openTo: PropTypes.string
};