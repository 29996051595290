import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import useSave from "Hooks/useSave";
import {crudFunctions} from "api";
import {TRANSPORTISTA} from "api/TYPES";
import { Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {post, get, put, remove, upload, init} from "api";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
const Joi = require("joi");


const Chofer = props => {

	const queryClient = useQueryClient();
	
	const actions = crudFunctions(TRANSPORTISTA);
	const {data:dataTransportista} = useQuery(TRANSPORTISTA.name, actions.getAll);


	const validation = Joi.object({
		empresa:Joi.object({
			"razon_social":Joi.string().required(),
			"rfc":Joi.string().required(),
			"telefono":Joi.string().required(),
		}),
		contacto:Joi.object({
			"nombre":Joi.string().required(),
			"apellido_p":Joi.string().required(),
			"correo":Joi.string().required(),
			"tel_celular":Joi.string().required(),
		}),
		direcciones:Joi.array().items(Joi.object({
			"calle":Joi.string().required(),
			"numero":Joi.string().required(),
			"colonia":Joi.string().required(),
			"cp":Joi.string().required(),
		})).min(1),
		"tiempo_operacion":Joi.string().required(),
		"num_unidades":Joi.string().required(),
		"cobertura":Joi.array().min(1).items(Joi.string()).required(),
		"operacion":Joi.string().required(),
	});
	const {data, onChange, onFile, removeFile, remove, save, status, isValid, setValid} = useSave({
		validation: validation,
		data:dataTransportista.transportista,
		crudActions: {
			insert:async ({files, ...data})=>{
				await post("/transportistas/me/setup", data);
				queryClient.invalidateQueries(TRANSPORTISTA.name);
				props.onSave();
	
			},
			update:async (id, {files, ...data})=>{
				await post("/transportistas/me/setup", data);
				queryClient.invalidateQueries(TRANSPORTISTA.name);
				props.onSave();

				// await put(`/transportistas/me`, data);
				// if(files){
				// 	const r = await upload(`/transportistas/me`, files);
				// 	return r;
				// }
			}
		}
	});


	const elements = React.useMemo(()=>[
		{
			type: "text",
			label: "Nombre de la empresa (obligatorio)",
			name: "empresa.razon_social",
		},
		{
			type: "text",
			label: "RFC (obligatorio)",
			name: "empresa.rfc",
		},
		{
			type: "text",
			label: "Nombre de la persona de contacto de la empresa (obligatorio)",
			name: "contacto.nombre",
		},
		{
			type: "text",
			label: "Apellido de la persona de contacto de la empresa (obligatorio)",
			name: "contacto.apellido_p",
		},
		{
			type: "text",
			label: "Correo electrónico (obligatorio)",
			name: "contacto.correo",
		},
		{
			type: "text",
			label: "Teléfono celular (obligatorio)",
			name: "contacto.tel_celular",
		},
		{
			type: "text",
			label: "Teléfono de la empresa (obligatorio)",
			name: "empresa.telefono",
		},
		{
			type: "group",
			name: "direccion",
			label: "Dirección",
			fields:[
				{
					type: "text",
					label: "Calle (obligatorio)",
					name: "direcciones.0.calle"
				},
				{
					type: "text",
					label: "Número (obligatorio)",
					name: "direcciones.0.numero"
				},
				{
					type: "text",
					label: "Colonia (obligatorio)",
					name: "direcciones.0.colonia"
				},
				{
					type: "text",
					label: "Código postal (obligatorio)",
					name: "direccion.0.cp"
				},
				
			]
		},
		{
			type: "calendar",
			label: "Fecha de inscripción en la Secretaria de Comunicaciones y Transporte",
			name: "fecha_inscripcion_sct"
		},
		{
			type: "radio",
			label: "¿Cuánto tiempo llevan operando? (obligatorio)",
			name: "tiempo_operacion",
			options:[
				{value:"<3", label:"Menos de 3 años"},
				{value:"3 a 7", label:"De 3 a 7 años"},
				{value:">7", label:"Más de 7 años"}
			],
		},
		{
			type: "radio",
			label: "¿Cuántas unidades tienen en su flotilla? (obligatorio)",
			name: "num_unidades",
			options:[
				{value:"1 a 5", label:"1 a 5"},
				{value:"6 a 10", label:"6 a 10"},
				{value:"11 a 20", label:"11 a 20"},
				{value:">50", label:"Más de 50"}
			],
		},
		{
			type: "select",
			isMulti:true,
			label: "Elige los estados donde puedes hacer recolecciones (obligatorio)",
			subLabel:"Escoge todos los estados donde tienes facilidad para realizar recolecciones",
			name: "cobertura",
			options:[
				{ value:"Aguascalientes", label:	"Aguascalientes" },
				{ value:"Baja California", label:	"Baja California" },
				{ value:"Baja California Sur", label:	"Baja California Sur" },
				{ value:"Campeche", label:	"Campeche" },
				{ value:"Chiapas", label:	"Chiapas" },
				{ value:"Chihuahua", label:	"Chihuahua" },
				{ value:"Coahuila", label:	"Coahuila de Zaragoza" },
				{ value:"Colima", label:	"Colima" },
				{ value:"Durango", label:	"Durango" },
				{ value:"Guanajuato", label:	"Guanajuato" },
				{ value:"Guerrero", label:	"Guerrero" },
				{ value:"Hidalgo", label:	"Hidalgo" },
				{ value:"Jalisco", label:	"Jalisco" },
				{ value:"Estado de México", label:	"Estado de México" },
				{ value:"Michoacán", label:	"Michoacán" },
				{ value:"Morelos", label:	"Morelos" },
				{ value:"Nayarit", label:	"Nayarit" },
				{ value:"Nuevo León", label:	"Nuevo Leon" },
				{ value:"Oaxaca", label:	"Oaxaca" },
				{ value:"Puebla", label:	"Puebla" },
				{ value:"Querétaro", label:	"Querétaro" },
				{ value:"Quintana Roo", label:	"Quintana Roo" },
				{ value:"San Luis Potosí", label:	"San Luis Potosi" },
				{ value:"Sinaloa", label:	"Sinaloa" },
				{ value:"Sonora", label:	"Sonora" },
				{ value:"Tabasco", label:	"Tabasco" },
				{ value:"Tamaulipas", label:	"Tamaulipas" },
				{ value:"Tlaxcala", label:	"Tlaxcala" },
				{ value:"Veracruz", label:	"Veracruz" },
				{ value:"Yucatán", label:	"Yucatan" },
				{ value:"Zacatecas", label:	"Zacatecas" },
				{ value:"Ciudad de México", label:	"CDMX" }
			],
		},
		{
			type: "radio",
			label: "¿Cómo definirían su operación? (obligatorio)",
			sublabel:"Seleccione todas las respuestas que definan su empresa",
			name: "operacion",
			options:[
				{value:"Tenemos clientes que nos van solicitando trabajo día a día", label:"Tenemos clientes que nos van solicitando trabajo día a día"},
				{value:"Tenemos convenios firmados con nuestros clientes y ciertas unidades están com- prometidas a ellos", label:"Tenemos convenios firmados con nuestros clientes y ciertas unidades están com- prometidas a ellos"},
				{value:"Le trabajamos a retailers como Walmart / Coppel", label:"Le trabajamos a retailers como Walmart / Coppel"},
			],

		},
		
	],[]);
	return (
		<div style={{maxWidth:600, margin:"auto", }}>
			<Title>Alta de proveedores</Title>
			<H4>Por favor completa la siguiente información de tu empresa</H4>
			<ModalForm hideBox elements={elements} onChange={onChange} data={data} onFile={onFile} removeFile={removeFile}>
				{status.status==="LOADING" && <CircularProgress />}
				{status.status==="ERROR" && <Alert severity="error">{status.errorMsg}</Alert>}
				{status.status!=="LOADING" && <Button variant="contained" color="primary" onClick={save}>Guardar</Button>}

			</ModalForm>
		</div>
	);
};
export default Chofer;

const Title = styled.h2`
	margin:20px;
`;
const H4 = styled.h4`
	margin:20px;
`;