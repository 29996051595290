import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Element from "./index";

export default function ModalElement (props) {
	return (
		<Element
			{...props}
			style={{marginBottom:"20px"}}
			element={props.element}
			filterBy={props.filterBy}
			value={props.value}
			onChange={props.onChange} />
	);
}

ModalElement.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	filterBy: PropTypes.any,
};