import React, {useMemo} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import useSave from "Hooks/useSave";
import { useQuery, useQueryClient } from "react-query";
import {crudFunctions, put, upload, post} from "api";
import {TRANSPORTISTA} from "api/TYPES";
import { Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import InitialData from "./InitialData";
import { Alert } from "@material-ui/lab";

const Chofer = props => {
	const queryClient = useQueryClient();
	
	const actions = crudFunctions(TRANSPORTISTA);
	const {data:dataTransportista} = useQuery(TRANSPORTISTA.name, actions.getAll);

	const {data, onChange, onFile, removeFile, remove, save, status, isValid, setValid} = useSave({
		data:dataTransportista?.transportista,
		crudActions: {
			
			update:async (id, {files, ...data})=>{
				await put("/transportistas/me", data);
				queryClient.invalidateQueries(TRANSPORTISTA.name);
				if(files){
					const r = await upload("/transportistas/me/documentos", files);
					return r;
				}
			},
			insert:async (id, {files, ...data})=>{
				await put("/transportistas/me", data);
				queryClient.invalidateQueries(TRANSPORTISTA.name);
				if(files){
					const r = await upload("/transportistas/me/documentos", files);
					return r;
				}
			}
		}
	});
	const revisar = async () => {
		await post("/transportistas/me/revisar");
		queryClient.invalidateQueries(TRANSPORTISTA.name);

	};
	const canRevisar = useMemo(()=>{
		if (data && data.documentos?.cif?.archivo?.path && data.documentos?.cif?.status!=="rechazado" 
						&& data.documentos?.ine?.archivo?.path && data.documentos?.ine?.status!=="rechazado" 
						&& data.documentos?.comprobante_domicilio?.archivo?.path && data.documentos?.comprobante_domicilio?.status!=="rechazado"
						&& data.documentos?.fachada?.archivo?.path && data.documentos?.fachada?.status!=="rechazado"
						&& data.documentos?.edo_cuenta?.archivo?.path && data.documentos?.edo_cuenta?.status!=="rechazado") {
			return true;
		}
	},[data]);
	
	const elements = React.useMemo(()=>[
		{
			type: "fileReview",
			label: "Constancia de situación fiscal",
			name: "documentos.cif",
			acceptFiles:true
		},
		{
			type: "fileReview",
			label: "Identificación oficial vigente del representante legal",
			name: "documentos.ine",
			acceptFiles:true
		},
		{
			type: "fileReview",
			label: "Comprobante de domicilio no mayor a 3 meses",
			name: "documentos.comprobante_domicilio",
			acceptFiles:true
		},
		{
			type: "fileReview",
			label: "Foto de la fachada de su oficinas o patio",
			name: "documentos.fachada",
			acceptFiles:true
		},
		{
			type: "fileReview",
			label: "Permiso activo de operación ante la Secretaria de Comunicaciones y Transporte",
			name: "documentos.inscripcion_sct",
			acceptFiles:true
		},
		{
			type: "fileReview",
			label: "Datos bancarios para recibir pagos",
			name: "documentos.edo_cuenta",
			acceptFiles:true
		
		}
	],[]);
	const [step, setStep] = React.useState(2);

	return (
		<Container className="bg-white rounded-xl flex flex-col gap-2 p-5">
			{step===1 && <InitialData onSave={()=>setStep(2)} />}
			{step===2 &&<>
				<h2 style={{textAlign:"center",marginTop:30}} className="font-black text-blue text-2xl">Asegurate de subir todos tus documentos de manera legible</h2>
				<Button style={{marginBottom:30,textAlign:"center",margin:"auto"}} color="secondary" className="font-black text-gray text-sm" onClick={()=>setStep(1)}>Modificar datos de la empresa</Button>
				<ModalForm hideBox elements={elements} onChange={onChange} data={data} onFile={onFile} removeFile={removeFile}>
					{status.status==="LOADING" && <>
						<CircularProgress />
					</>}
					{status.status==="ERROR"&& <>
						<Alert severity="error">{status.errorMsg}</Alert>
					</>}
					{status.status!=="LOADING" && <>
						<Button variant="contained" color="secondary" onClick={save}>Guardar</Button>
						<Button color="primary" disabled={!canRevisar} onClick={revisar}>Enviar a revision</Button>
					</>}
					
					{/* {status} */}
				</ModalForm>
			</>}
		</Container>
	);
};

export default Chofer;
const Container = styled.div`
	// width:700px;
	// max-width:90%;
	// margin:auto;
`;
