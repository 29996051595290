import React, { useState, useEffect, useRef } from "react";
import * as immutable from "object-path-immutable";
import PropTypes from "prop-types";
import useSkipFirstRender from "./useSkipFirstRender";
import {useDialog} from "Contexts/DialogContext";
import SaveAsModal from "components/Modals/SaveAs"
import {  useQueryClient } from "react-query"
var shortid = require("shortid");
const _ = require("lodash");
const Joi = require('joi');


// Get QueryClient from the context

const useSave = (props) => {
	const queryClient = useQueryClient();

	const [openDialog, closeDialog] = useDialog();

	const [state, setState] = useState(props.data);
	const [changes, setChanges] = useState();
	const [status, setStatus] = useState({status:"LOADED"});
	const [isValid, setValid] = useState(true);
	const timerId = useRef(-1);
	//useSelector(state=>state[props.type?.name]?.view);

	useEffect(()=>{
		if(props.data || changes){
			setState(()=>{
				let data ={...props.data};
				_.merge(data, changes);
				return data;
			});
		}
	},[props.data]);
	

	const onChange = React.useMemo(
		() => (value, name) => {
			console.log({value,name})
			if (name === undefined) {
				setState(value);
				setChanges((state)=>({ ...state, ...value }));
			} else {
				setState(state=>{
					return immutable.set(state, name, value);
				});
				setChanges(state=>{
					return immutable.set(state, name, value);
				});
				// setChanges((state)=>({ ...state, [name]: value }));
			}
		},[]);
	
	useSkipFirstRender(()=>{
		if(props.autoSave){
			
			const {_id, parent_id, ...rest} = changes;
			if(Object.keys(rest).filter(k=>{
				return rest[k]!==undefined;
			}).length>0){
				setStatus({status:"LOADING"});
				clearInterval(timerId.current);
				timerId.current = setInterval(saveData, 2000);
			}
		}
	},[changes]);

	
	const addFile = (files, field) => {

		if(Array.isArray(files.files)){
			const mappedFiles = files.files.map(file=>{
				const id=shortid.generate();
				return {
					name:field, 
					file:file, 
					_id:id 
				};
			});

			setChanges((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
			setState((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
		}
		else{

			const id=shortid.generate();


			setChanges((state)=>({
				...state, 
				"files":
					{
						name:field, 
						file:files.files, 
						_id:id 
					}
			}));
			setState((state)=>({
				...state, 
				"files":
					{
						name:field, 
						file:files.files, 
						_id:id 
					}
			}));
		}
	};

	const removeFile = (file) => {
		const mappedFiles = changes.files.filter(f=>f.file != file);	
		setChanges((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
		setState((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
	};

	const remove = (idx, field) => {
		setChanges((state)=>immutable.del(state, `${field}.${idx}`));
		setState((state)=>immutable.del(state, `${field}.${idx}`));
	};

	const _insert = async (data) => {
		
		validate();
		try{
			const response = await props.crudActions.insert(data);
			setStatus({status:"SUCCESS"});
			setChanges({});
			queryClient.invalidateQueries(props.type.name)
			props.onInsert && props.onInsert(response?.data);
		}catch(error){
			console.error({error});
			setStatus({status:"ERROR", errorMsg:error.errmsg});
		}
	};
	const validate = () => {
		if(props.validation){
			const filesValidation = changes?.files?.reduce((acc, file)=>({...acc, [file.name]:"archivo"}),{}) || {};

			let {value, error} = props.validation.validate(_.merge({},props.data, changes, filesValidation),{stripUnknown:true, abortEarly:false});
			if(error){
				setStatus({status:"ERROR", errorMsg:makeFriendlyError(error)});
				return;
			}
		}
	};
	const saveData = async (options={}) => {
		try{
			clearInterval(timerId.current);

			let changesWithIds = {...changes, _id:props.data?._id, parent_id:props.parent_id};
	
			const uploadChanges = Object.keys(changesWithIds).reduce((acc, item)=>{
				_.set(acc, item, changesWithIds[item]);
				return acc;
			},{});
	
			console.log({uploadChanges, options, props});
			setStatus({status:"LOADING"});
	
			if(options.saveAs){
				
				if(props.saveAsNameKey){
					openDialog({
						onClose:(data)=>{
							if(data){
								let uploads = {...state, parent_id:props.parent_id};
								uploads[props.saveAsNameKey] = data;
								_insert(uploads);
							}else{
								setStatus({status:"LOADED"});
							}
						},
						Content: SaveAsModal
						
					});
				}else{
					let uploads = {...state, parent_id:props.parent_id};
					_insert(uploads);
				}
				
					
			}
			else if(props.forceNew || !props.data){
				const uploads = {...state, parent_id:props.parent_id};
				_insert(uploads);
	
			}
			else{
				const uploads = {...uploadChanges, parent_id:props.parent_id, _id:props.data._id};
	
				try{
					console.log("LOADING",props)
					const response = await props.crudActions.update(props.data?._id, uploads);
					setStatus({status:"SUCCESS"});
					setChanges({});
					queryClient.invalidateQueries(props.type?.name);
	
					props.onUpdate && props.onUpdate(response?.data);
				}catch(error){
					console.log({error});
					setStatus({status:"ERROR", errorMsg:error.errmsg || error});
				}
			} 
		}catch(e){
			console.error(e);
		}
		
	};

	function makeFriendlyError(error){
		console.log({error});
		return error.details.reduce((acc, detail)=>{
			const label = detail.context.label;
			const type = detail.type;
			if(acc?.length)acc+="\n";
			switch(type){
			case "string.empty":case"string.required":case"any.required":
				return `${acc}${label} no puede estar vacio`;
			case "number.max":{
				const limit = error.details[0].context.limit;
				return `${acc}${label} no puede ser mayor de ${limit}`;
			}
			case "number.min":{
				const limit = error.details[0].context.limit;
				return `${acc}${label} no puede ser menor de ${limit}`;
			}
			case "any.only":{
				const valids = error.details[0].context.valids;
				return `${acc}${label} solo puede ser uno de los siguientes valores: ${valids}`;
			}
			default:
				return acc+detail.message;
			}
		},"");
		
	}
	return {data:state, onChange, onFile:addFile, removeFile, remove, save:saveData, status, setValid, isValid};

};
export default useSave;

useSave.propTypes = {
	data: PropTypes.object,
	autoSave: PropTypes.bool,
	parent_id: PropTypes.string,
	onInsert: PropTypes.func,
	forceNew: PropTypes.bool,
	onUpdate: PropTypes.func,
	type: PropTypes.object,
	saveAs: PropTypes.bool
};