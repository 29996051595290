import React, {useMemo, useState, useCallback} from "react";
import File from "../File/File";
import styled from "styled-components";
const Img = styled.img`
	width:30px;
	height:30px;
	object-fit:contain;
	margin-right:5px;
`;
const Span = styled.span`
	color:red;
	line-height:30px;
`;
const ImgContainer = styled.div`
	display:flex;
	align-contents:center;
`;
export default function FileReview(props){
	switch(props?.value?.status){
	case "rechazado":
	{
		return 	<div className="flex">
			<ImgContainer>
				<Img src={require("assets/img/icons/rechazado.png")} />
				<Span>
					{props.value?.notas}
				</Span>
			</ImgContainer>
			
			<File hideFile {...props} value={null} name={`${props.name}.archivo`}  />
		</div>;
	}
	case "aceptado":
	{
		return 	<>
			<Img src={require("assets/img/icons/aceptado.png")} />
		</>;
	}
	case "revision":
	{
		return 	<div className="flex">
			<Img src={require("assets/img/icons/pendiente.png")} />
			<File hideFile {...props} value={props.value?.archivo} name={`${props.name}.archivo`}  />
		</div>;
	}
	default:
	{
		return 	<>
			<File hideFile {...props} value={props.value?.archivo} name={`${props.name}.archivo`}  />
		</>;
	}
	}
}