import React from "react";
import Element from "../Element";
import PropTypes from "prop-types";
const Grid = props => {
	return (
		
			<div className={`grid grid-cols-2 gap-5 ${props.className}`}>
				{props.element.fields.map(field => (
					
					<Element
						element={field}
						value={props.value[field.name]}
						onChange={props.onChange}
						onFile={props.onFile}
						removeFile={props.removeFile}
						files={props.files}
						{...field}
						// showLabel={false}
					/>
					
				))}
			</div>
	);
};
export default Grid;