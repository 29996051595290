import React from "react";
import PropTypes from "prop-types";
import { Popover, ClickAwayListener } from "@material-ui/core";
import TextField from "components/FormComponents/Text/Text";
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter(props) {
	const {
		column: { filterValue = [], preFilteredRows, setFilter, id, Header },
	} = props;
  const [openPicker, setOpenPicker] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	return (<>
			<div ref={anchorEl} onClick={(event)=>(setOpenPicker(true), setAnchorEl(event.currentTarget))} className={`bg-white border border-gray-light rounded px-5 py-2 w-full ${props.className}`}>
			{filterValue[0]&& filterValue[1] ? <>{filterValue[0]} - {filterValue[1]}</> : <span className="text-gray-light">Buscar {Header}</span>}
		</div>
		<Popover
			id={id}
			open={openPicker}
			anchorEl={anchorEl}
			// onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<ClickAwayListener onClickAway={()=>setOpenPicker(false)}>
				<div className="flex p-5 gap-2">
					<TextField
						value={filterValue[0] || ""}
						type="number"
						onChange={val => {
							// const val = e.target.value;
							setFilter([val ? val :undefined, filterValue[1]]);
						}}
						placeholder={`Min`}
						variant="outlined"
						label="Desde"
						size="small"
					/>
					<div style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}}>a</div>
					<TextField
						value={filterValue[1] || ""}
						type="number"
						label="Hasta"

						onChange={val => {
							// const val = e.target.value;
							// console.log({val,filterValue})

							setFilter([filterValue[0], val ? val : undefined]);
						}}
						placeholder={`Max`}
						variant="outlined"
						size="small"

					/>
					<button onClick={()=>setOpenPicker(false)}>Filtrar</button>
				</div>
			</ClickAwayListener>
		</Popover>
	</>
		
	);
}
export default NumberRangeColumnFilter;

NumberRangeColumnFilter.propTypes = {
	column: PropTypes.object
};