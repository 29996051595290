import React from "react";
import Element from "../Element";
import PropTypes from "prop-types";

import { Card, Grid, Typography } from "@material-ui/core";
const Group = props => {
	return (
		<Card
			className={props.className}
			// variant="outlined"
			style={
				{
					/*height: "calc(100% - 30px)"*/
				}
			}
		>
			<div
				
				// direction={props.horizontal?"row":"column"}
				style={props.horizontal ? {padding: "20px", display: "flex", flexDirection:"row", alignItems:"center"} : { padding: "20px" }}

				// justify="space-between"
				// spacing={2}
			>
				{props.element.fields.map(field => (
					<div key={field.id || field.name} style={{marginBottom:"8px"}}  >
						{/* <Grid
							container
							alignItems="center"
							// justify="space-between"
							spacing={2}
						>
							<Grid xs={12} md={6} item> */}
						<div style={{display: "flex", alignItems: "center"}}>
							<div>
								<Typography style={{fontWeight:"bold"}}>{field.label}</Typography>
								<Typography>{field.subLabel}</Typography>
							</div>
							

							
							{field.buttons && field.buttons.map((Ele,idx) => (
								<div style={{marginLeft: "5px"}}>
									{Ele}
								</div>
							)) 
							}
						</div>
						{/* </Grid>
							<Grid xs={12} md={6} item> */}
						<Element
							element={field}
							value={props.value[field.name]}
							onChange={props.onChange}
							onFile={props.onFile}
							removeFile={props.removeFile}
							files={props.files}
							// {...props}
							// showLabel={false}
						></Element>
						{/* </Grid> */}
						{/* </Grid> */}
					</div>
				))}
			</div>
		</Card>
	);
};
export default Group;

Group.propTypes = {
	className: PropTypes.string,
	horizontal: PropTypes.bool,
	element: PropTypes.object,
	value: PropTypes.object,
	onChange: PropTypes.func
};