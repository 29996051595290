import React from "react";

import { FormGroup } from "@material-ui/core";
import PropTypes from "prop-types";
import ModalElement from "../Element/ModalElement";
import getData from "../getData";
import Box from "../../Box/Box";
import "./ModalForm.scss";
const ModalForm = props => {

	const Elements = <>
		{props.elements.map((element, idx) => (
			<div className="flex gap-x-5">
				<ModalElement
					{...props}
					key={element.name+idx}
					element={element}
					value={getData(element, props.data)}
					files={props.data?.files}
					onChange={props.onChange}
					onFile={props.onFile}
				></ModalElement>
			</div>
		))}
		{props.children}
	</>;
	return (<>
		<FormGroup className="">
			
			<div className="flex flex-col gap-y-5">
				{props.elements.map((element, idx) => <ModalElement
						{...props}
						key={element.name+idx}
						element={element}
						value={getData(element, props.data)}
						files={props.data?.files}
						onChange={props.onChange}
						onFile={props.onFile}
					/>
				)}
				{props.children}
			</div>
				
		</FormGroup></>
	);
};

export default ModalForm;

ModalForm.propTypes = {
	data: PropTypes.object,
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	elements: PropTypes.array,
	onChange: PropTypes.func,
	onFile: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};