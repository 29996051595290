import React from "react";
import {Switch, FormLabel} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

export default function Boolean(props) {
	const handleChange = event => {
		event.persist();
		props.onChange(event.target.checked, props.name);
	};

	return (
		<>
			<FormControl component="fieldset" disabled={ (props.value!==undefined && props.disableOnValue) || props.readOnly }>
				<FormLabel component="legend">{props.label}</FormLabel>
				<Switch onChange={handleChange} value={props.value ? props.value : ""} />
				{/* <RadioGroup
					color="primary"
					
					onChange={handleChange}
				>
					<FormControlLabel
						value={false}
						control={<Radio color="primary" checked={!props.value} />}
						label={props.falseLabel ? props.falseLabel : "No"}
					/>
					<FormControlLabel
						value={true}
						control={<Radio color="primary" checked={props.value} />}
						label={props.trueLabel ? props.trueLabel : "Si"}
					/>
				</RadioGroup> */}

        
			</FormControl>
		</>
	);
}

Boolean.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.bool,
	disableOnValue: PropTypes.bool,
	falseLabel: PropTypes.bool,
	trueLabel: PropTypes.bool
};