import React from "react";
import {useSelector} from "react-redux";
import Select from "components/FormComponents/Select/Select";
import {Autocomplete} from "@material-ui/lab";
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import "./RefFilter.css";
import {crudFunctions} from "api";
import { useQuery } from "react-query";
import _ from "lodash"
// This is a custom filter UI for selecting
// a unique option from a list
export default function SelectColumnFilter(props) {
	const {
		column: { filterValue, setFilter, id, labelAccessor, Header },
	} = props;

	

	
	const actions = crudFunctions(props.column.type);

	let {data} = useQuery(props.column.type.name, actions.getAll);

	data = data?.filter(d=>!d._trash);
	data = data?.sort((rowA, rowB) => {
		let a = rowA.nombre;
		let b = rowB.nombre;
		a = a ? a.toLowerCase() : a;
		b = b ? b.toLowerCase() : b;
		return a === b ? 0 : a > b ? 1 : -1;
	});
	const options = React.useMemo(() => {
		
		return [{value:"",label:"Todos"}, ...new Set(data?.map(d=>({label:_.get(d, labelAccessor || "nombre" ||""), value:d._id})))];
	}, [data]);

	
	const handleChange = (e, v) => {
		setFilter(v? v.value: "");
	};

	return <Select label={`Buscar ${Header}`} value={filterValue} options={options || []} onChange={setFilter} style={{background:"white", border:"1px solid #CACEDA", borderRadius:5, borderColor:"#CACEDA",}} placeholderStyle={{color:"#CACEDA"}} />;


}


SelectColumnFilter.propTypes = {
	column: PropTypes.object
};